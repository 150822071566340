.abstract-section {
    position: relative;
    max-height: 450px;
    text-align: justify; /* Menyelaraskan teks ke tengah */

  }
  .abstract-title {
    position: sticky;
    top: 0;
    background: white; /* Background color to cover the content when scrolling */
    z-index: 1; /* Ensure title is above the scrolling content */
    padding: 0.5rem;
  }
  .abstract-content {
    
    height:300px;
    overflow: auto;
    border: 1px solid black;
    padding: 1vw 1vw 0 1vw;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    
  }
  .abstract-content::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  .pop-background{
    max-height: 85vh;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; 
  }
  .pop-background::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  
  /* Untuk tampilan mobile */
@media (max-width: 767px) {
  .abstract-content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    padding: 3vw;
  }
 
  .popupDataPaper .bg-white {
    width: 90%;
    min-height: 70%;
    position: relative;
  }


  .pop-background{
    max-height: 85vh;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; 
  }
  .pop-background::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  .popupDataPaper .abstract-content {
   height: 350px;
  }
  .btn{
    height: 7vh;
    font-size: 0.8rem;
    width: 100%;
  }
  .title{
    font-size: 1rem;
    
  }
  .class-button{
    position: relative;
  
  width: 100%; /* Pastikan tombol menempati lebar penuh */
  display: flex;
  justify-content: center; /* Pusatkan tombol secara horizontal */
  }
}


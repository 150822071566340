@import url("https://fonts.googleapis.com/css2?family=Noticia+Text:wght@400;700&display=swap");

.headerText {
    font-family: "Noticia Text";
    font-weight: 900;
    font-size: 60px;
    text-align: center;
    padding-top: 40px;
}

.subheaderText {
    font-family: "Noticia Text";
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    width: 60%;
    margin: 0 auto;
}

.opensourceText {
    font-family: "Noticia Text";
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    width: 60%;
    margin: 10px auto;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.graphContainer {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgb(0, 0, 0);
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    margin: 0 auto;
    margin-bottom: 10px;
}

.footer {
    font-family: "Noticia Text";
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 100px;
}

.inputContainer {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}


.minimap{
  height: 157px; 
  width: 157px; 
  position: absolute; 
  top: 10px; 
  left: 10px;
  border: 2px solid black; 
  border-radius:10px;
  padding:2px
 }

.generateButton:disabled {
    background-color: rgb(0, 0, 0, 0.5);
    cursor: wait;
}



.background-animate {
    background-size: 400%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}

@keyframes AnimationName {
    0%,
    100% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it is above other elements */
    backdrop-filter: blur(5px); /* Add background blur */
}

.loading-container {
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading-text {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
}


  .buttonShowHide{
    display:none;
  }
  /* Untuk layar yang lebih besar */
 
  .graph-display{
    width: 90vw;

  } 
@media only screen and (max-width: 767px) {
    .buttonShowHide{
        display:contents;
    }
    
    
    /* Container utama menyesuaikan arah layout untuk mobile */
    .flex-cols {
      flex-direction: column-reverse; /* Membuat SideBox berada di bawah */
    }

    /* GraphDisplay */
    .graph-display {
      width:99%; /* Mengatur agar graph display juga penuh di layar */
      height: 100%; /* Biarkan tinggi menyesuaikan */
      margin: 0;
      margin-left: 2px;
      margin-top: 1px;
      margin-bottom: 3px;
     
    }
    .sidebox {
        width: 99%;
       height: 100%;
        margin: 0;
        margin-left: 2px;
      }
    /* Input dan tombol */
    .paperData,
    .PaperLimit,
    .startYear,
    .endYear,
    .generateButton,
    .clearButton {
      width: 100%; /* Memastikan semua elemen form memenuhi lebar layar */
      height: 40%;
    }
  
   
   
    
  }
  
  